import { handleCartError } from "../helper";
export const cleanCostBase = ({ execute }) => async (cartId, version, paymentCostId) => handleCartError(() => execute({
    uri: `/ctp/carts/${cartId}`,
    method: "POST",
    body: {
        version,
        actions: [
            paymentCostId ? { action: "removeCustomLineItem", customLineItemId: paymentCostId } : null,
            { action: "recalculate", updateProductData: true },
        ].filter(Boolean),
    },
}), version);
