import { typedBoolean } from "./helper";
const country = process.env.NEXT_PUBLIC_COUNTRY;
export const getAvailableCountryCodesInUS = () => ["AT", "ES", "FR", "LU"];
export const getCountryNameByCode = (countryCode, countryName) => {
    switch (countryCode) {
        case "DE":
            return "Germany";
        case "NL":
            return "Netherlands";
        case "BE":
            return "Belgium";
        case "FR":
            return "France";
        case "AT":
            return "Austria";
        case "ES":
            return "Spain";
        case "LU":
            return "Luxembourg";
        case "US":
            return "Global";
        default:
            return countryName || countryCode;
    }
};
export const verifyCountryTipInfo = (countryCode) => {
    const availableCountryCodesInUS = getAvailableCountryCodesInUS();
    if (country === "US")
        return !availableCountryCodesInUS.includes(countryCode.toUpperCase());
    return countryCode !== "select" && countryCode !== country;
};
// Get from CountryList key, value string separated by [,] from translation file and return dictionary of countries with code country as key and string value as country name
export const getListCountry = (CountryList) => CountryList.split(",")
    .filter(typedBoolean)
    .reduce((acc, current) => {
    const [key, value] = current === null || current === void 0 ? void 0 : current.split(":").map((i) => i === null || i === void 0 ? void 0 : i.trim()).filter(typedBoolean);
    if (!key || !value)
        return acc;
    return { ...acc, [key]: value };
}, {});
export const getUSListCountry = (countryList) => {
    const availableCountryCodesInUS = getAvailableCountryCodesInUS();
    return availableCountryCodesInUS.map((code) => ({ code: code, name: countryList[code] }));
};
export const verifyAvailableCountry = (countryCode) => {
    const availableCountryCodesInUS = getAvailableCountryCodesInUS();
    return country === "US" ? availableCountryCodesInUS.includes(countryCode) : countryCode === country;
};
export const getJcCountryConvert = (countryCode, countryName) => {
    switch (countryCode) {
        case "BE":
            return "Belgium";
        case "NL":
            return "Nederland";
        case "DE":
            return "Duitsland";
        case "LU":
            return "Luxemburg";
        default:
            return countryName || countryCode;
    }
};
