import { checkoutTransactionEventSent, convertCentsToEuros, getRevenue, localStorage, transGtmId, typedBoolean, } from "@jog/shared";
import { makeAutoObservable } from "mobx";
export class GtmStore {
    constructor(options) {
        this.options = options;
        this.gtmCheckoutBillingForm = (isLogged, productsData, isUserLoggedWithAmplify = false, isRegistration = false) => {
            const step = 4;
            if (window === null || window === void 0 ? void 0 : window.dataLayer) {
                if (isLogged && !isUserLoggedWithAmplify) {
                    // user was already logged when he came to checkout. Send also data about gtm step 2 and 3 he didn't see
                    this.gtmCheckoutEmailCheck(isLogged, productsData);
                    this.gtmCheckoutAmplifyLogin(isLogged, productsData);
                    this.gtmCheckoutCreateDataLayer(isLogged, productsData, step, "logged in");
                }
                else {
                    if (isUserLoggedWithAmplify) {
                        // user logged from Amplify on checkout (gtm step 3)
                        this.gtmCheckoutCreateDataLayer(isLogged, productsData, step, "logged in");
                    }
                    else {
                        if (isRegistration) {
                            // just registered (didn't see step 3)
                            this.gtmCheckoutAmplifyLogin(isLogged, productsData);
                            this.gtmCheckoutCreateDataLayer(isLogged, productsData, step, "nieuw account aangemaakt");
                        }
                        else {
                            // anonymous (didn't see step 3)
                            this.gtmCheckoutAmplifyLogin(isLogged, productsData);
                            this.gtmCheckoutCreateDataLayer(isLogged, productsData, step, "bestellen zonder account");
                        }
                    }
                }
            }
        };
        makeAutoObservable(this);
    }
    gtmTransactionData(paymentOrderInfo) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        if (window === null || window === void 0 ? void 0 : window.dataLayer) {
            if (paymentOrderInfo && paymentOrderInfo.lineItems) {
                const productsPaymentData = paymentOrderInfo.lineItems.map((product) => {
                    const productPrice = parseFloat(convertCentsToEuros(product.prices.totalPrice.centAmount / product.quantity));
                    return {
                        name: product.name.en,
                        id: transGtmId({ articleName: product.articleName, colorCode: product.colorCode }),
                        price: productPrice,
                        brand: "",
                        category: product.categoryAsUrl,
                        variant: "",
                        quantity: product.quantity,
                    };
                });
                const { shipping, tax, revenue } = getRevenue(paymentOrderInfo);
                const data = {
                    ecommerce: {
                        purchase: {
                            actionField: {
                                id: paymentOrderInfo.orderNumber,
                                affiliation: this.options.transactionSendEventAffiliation,
                                revenue: (revenue + shipping).toFixed(2),
                                tax,
                                shipping,
                                coupon: (paymentOrderInfo.vouchers.length > 0 && ((_a = paymentOrderInfo.vouchers[0]) === null || _a === void 0 ? void 0 : _a.code)) || "",
                                metric1: paymentOrderInfo.vouchers.length > 0
                                    ? convertCentsToEuros((_b = paymentOrderInfo.vouchers[0]) === null || _b === void 0 ? void 0 : _b.centAmount)
                                    : "",
                                email: (_c = paymentOrderInfo.billingAddress) === null || _c === void 0 ? void 0 : _c.email,
                                firstName: (_d = paymentOrderInfo.billingAddress) === null || _d === void 0 ? void 0 : _d.firstName,
                                lastName: (_e = paymentOrderInfo.billingAddress) === null || _e === void 0 ? void 0 : _e.lastName,
                                paymentMethod: (_k = (_j = (_h = (_g = (_f = paymentOrderInfo.paymentInfo) === null || _f === void 0 ? void 0 : _f.payments) === null || _g === void 0 ? void 0 : _g[0]) === null || _h === void 0 ? void 0 : _h.obj) === null || _j === void 0 ? void 0 : _j.paymentMethodInfo) === null || _k === void 0 ? void 0 : _k.method,
                            },
                            products: productsPaymentData,
                        },
                    },
                    hoeveelsteorder: 1,
                    event: "transaction-send",
                };
                const flag = checkoutTransactionEventSent(paymentOrderInfo.orderNumber);
                if (flag.exist()) {
                    data.event = "transaction";
                    window.dataLayer.push(data);
                }
                else {
                    window.dataLayer.push(data);
                    flag.mark();
                }
            }
        }
    }
    gtmTrustedShops(orderViewData) {
        var _a, _b, _c, _d, _e, _f, _g;
        if ((window === null || window === void 0 ? void 0 : window.dataLayer) && orderViewData) {
            const cartProductExtras = localStorage.getItem("cartProductExtras") || {};
            const lineItems = orderViewData.lineItems || [];
            const productReviews = lineItems.map((item) => {
                var _a, _b, _c, _d;
                return {
                    tsCheckoutProductUrl: (_b = (_a = cartProductExtras[item.productId]) === null || _a === void 0 ? void 0 : _a.url) !== null && _b !== void 0 ? _b : "",
                    tsCheckoutProductImageUrl: item.image,
                    tsCheckoutProductName: item.name.en,
                    tsCheckoutProductSKU: item.sku,
                    tsCheckoutProductGTIN: item.sku,
                    tsCheckoutProductBrand: (_d = (_c = cartProductExtras[item.productId]) === null || _c === void 0 ? void 0 : _c.brand) !== null && _d !== void 0 ? _d : "",
                };
            });
            window.dataLayer.push({
                event: "TrustedShops",
                ts_checkout: {
                    tsCheckoutOrderNr: orderViewData === null || orderViewData === void 0 ? void 0 : orderViewData.orderNumber,
                    tsCheckoutBuyerEmail: (_a = orderViewData === null || orderViewData === void 0 ? void 0 : orderViewData.billingAddress) === null || _a === void 0 ? void 0 : _a.email,
                    tsCheckoutOrderAmount: convertCentsToEuros((_b = orderViewData === null || orderViewData === void 0 ? void 0 : orderViewData.total) === null || _b === void 0 ? void 0 : _b.centAmount),
                    tsCheckoutOrderCurrency: "EUR",
                    tsCheckoutOrderPaymentType: (_g = (_f = (_e = (_d = (_c = orderViewData === null || orderViewData === void 0 ? void 0 : orderViewData.paymentInfo) === null || _c === void 0 ? void 0 : _c.payments) === null || _d === void 0 ? void 0 : _d[0]) === null || _e === void 0 ? void 0 : _e.obj) === null || _f === void 0 ? void 0 : _f.paymentMethodInfo) === null || _g === void 0 ? void 0 : _g.method,
                    tsCheckoutProducts: productReviews,
                },
            });
        }
    }
    gtmCheckoutCreateDataLayer(isLogged, productsData, step, optionText, email = "") {
        if ((window === null || window === void 0 ? void 0 : window.dataLayer) && productsData) {
            const products = productsData.filter(typedBoolean).map((product) => {
                return {
                    name: product.name.en,
                    id: transGtmId({ articleName: product.articleName, colorCode: product.colorCode }),
                    price: parseFloat(convertCentsToEuros(product.prices.firstPriceInTheCart.centAmount)),
                    brand: "",
                    category: product.categoryAsUrl,
                    variant: "",
                };
            });
            window.dataLayer.push({
                event: "checkout",
                ecommerce: {
                    checkout: {
                        actionField: {
                            step,
                            option: optionText || (isLogged ? "logged in" : "logged out"),
                            email,
                        },
                        products,
                    },
                },
            });
        }
    }
    gtmFromCartToCheckout(isLogged, productsData) {
        const step = 1;
        if (window === null || window === void 0 ? void 0 : window.dataLayer)
            this.gtmCheckoutCreateDataLayer(isLogged, productsData, step);
    }
    gtmCheckoutEmailCheck(isLogged, productsData) {
        const step = 2;
        if (window === null || window === void 0 ? void 0 : window.dataLayer)
            this.gtmCheckoutCreateDataLayer(isLogged, productsData, step);
    }
    gtmCheckoutAmplifyLogin(isLogged, productsData) {
        const step = 3;
        if (window === null || window === void 0 ? void 0 : window.dataLayer)
            this.gtmCheckoutCreateDataLayer(isLogged, productsData, step);
    }
    gtmCheckoutDeliveryAddress(isLogged, productsData, optionText) {
        const step = 5;
        if (window === null || window === void 0 ? void 0 : window.dataLayer)
            this.gtmCheckoutCreateDataLayer(isLogged, productsData, step, optionText);
    }
    gtmCheckoutClickedPaymentButton(isLogged, productsData, optionText, email) {
        const step = 6;
        if (window === null || window === void 0 ? void 0 : window.dataLayer)
            this.gtmCheckoutCreateDataLayer(isLogged, productsData, step, optionText, email);
    }
    gtmPaymentFinalisedOrdersPaymentPage(isLogged, productsData) {
        const step = 7;
        if (window === null || window === void 0 ? void 0 : window.dataLayer)
            this.gtmCheckoutCreateDataLayer(isLogged, productsData, step, " ");
    }
    getCartPageBasketOverview(cartView) {
        if (window === null || window === void 0 ? void 0 : window.dataLayer) {
            const products = cartView.body.lineItems.filter(typedBoolean).map((product) => {
                return {
                    id: transGtmId({ articleName: product.articleName, colorCode: product.colorCode }),
                };
            });
            window.dataLayer.push({
                event: "BasketOverview",
                products,
            });
        }
    }
    gtmEnhancedConversion(paymentOrderInfo) {
        var _a;
        if (window === null || window === void 0 ? void 0 : window.dataLayer) {
            const email = (_a = paymentOrderInfo === null || paymentOrderInfo === void 0 ? void 0 : paymentOrderInfo.billingAddress) === null || _a === void 0 ? void 0 : _a.email;
            if (email) {
                window.dataLayer.push({
                    email,
                    event: "fireEnhancedConversionTag",
                });
            }
        }
    }
}
